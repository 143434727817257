import { extendTheme, theme as chakraTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  components: {
    Popover: {
      variants: {
        responsive: {
          content: { width: "unset" },
          popper: {
            maxWidth: "unset",
            width: "unset"
          }
        }
      }
    },
    Input: {
      variants: {
        outline: {
          field: {
            background: "white"
          }
        }
      }
    },
    Tooltip: {
      baseStyle: {
        whiteSpace: "nowrap",
        fontSize: "xs",
        borderRadius: "lg",
        backgroundColor: "gray.600"
      }
    }
  },
  styles: {
    global: {
      html: {
        WebkitFontSmoothing: "antialiased", // Antialiasing.
        MozOsxFontSmoothing: "grayscale", // Antialiasing.
        // Change from `box-sizing: content-box` so that `width`
        // is not affected by `padding` or `border`.
        boxSizing: "border-box",
        fontSize: 16
      },
      body: {
        fontSize: 14
      },
      "*, *::before, *::after": {
        boxSizing: "inherit"
      },
      "html, body": {
        color: chakraTheme.colors.gray["500"]
      },
      ".firebase-emulator-warning": { display: "none" },
      ".kill_scrollbars": {
        "::-webkit-scrollbar": {
          display: "none"
        },
        "-ms-overflow-style": "none"
      }
    }
  },
  fonts: {
    brand: "'Muli', sans-serif",
    body: "Muli, sans-serif",
    heading: "Muli, sans-serif"
  },
  letterSpacings: {
    normal: "0.03em"
  },
  colors: {
    current: chakraTheme.colors.gray,
    error: chakraTheme.colors.red["500"],
    brand: {
      body: chakraTheme.colors.gray,
      buttonPrimary: chakraTheme.colors.blue,
      buttonSecondary: chakraTheme.colors.gray
    }
  }
});
