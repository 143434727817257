import { Box, chakra } from "@chakra-ui/react";

const Icon = () => (
  <Box as="g">
    <g transform="matrix(0.628116,0,0,0.628116,316.175,-12.5274)">
      <path
        className="icon-left"
        d="M281.1,300.5L221,357.5L221,293.5C221,263.4 245.4,239 275.5,239C293.2,239 308.9,247.4 318.8,260.4C311.1,254.4 301.5,250.9 291,250.9C266,250.9 245.7,271.2 245.7,296.2C245.7,297.6 245.8,299.1 245.9,300.5L281.1,300.5Z"
      />
    </g>
    <g transform="matrix(0.628116,0,0,0.628116,316.175,-12.5274)">
      <path
        className="icon-right"
        d="M330,293.5C330,323.6 305.6,348 275.5,348C267.2,348 259.4,346.1 252.3,342.8L274.1,322.1L275.5,322.1C276.3,322.1 277.1,322.1 277.8,322L278.3,322C292.8,320.6 304.1,308.3 304.1,293.5C304.1,277.7 291.3,264.8 275.4,264.8L274.1,264.8C273.1,264.8 272.2,264.9 271.2,265.1C273.6,263.2 276.2,261.6 279,260.4C283.5,258.4 288.5,257.3 293.7,257.3C310.9,257.3 325.3,269.3 329,285.5C329.1,285.5 330,288.8 330,293.5Z"
      />
    </g>
  </Box>
);

const LogoText = () => (
  <Box as="g" transform="matrix(1.46557,0,0,1.50163,537.115,199.494)">
    <g id="Group-2-copy">
      <g id="LeadPal" transform="matrix(55.8254,0,0,55.8254,0,0)">
        <path
          d="M0.066,-0.731C0.09,-0.735 0.114,-0.737 0.137,-0.737C0.16,-0.737 0.183,-0.735 0.207,-0.731L0.207,-0.206C0.207,-0.185 0.208,-0.169 0.211,-0.156C0.213,-0.143 0.217,-0.134 0.222,-0.127C0.227,-0.12 0.233,-0.115 0.241,-0.112C0.249,-0.109 0.259,-0.108 0.271,-0.108C0.276,-0.108 0.282,-0.108 0.289,-0.109C0.295,-0.11 0.301,-0.111 0.307,-0.112C0.314,-0.085 0.318,-0.058 0.318,-0.032L0.318,-0.018C0.318,-0.013 0.317,-0.008 0.316,-0.003C0.305,0 0.293,0.003 0.278,0.004C0.263,0.005 0.249,0.006 0.236,0.006C0.185,0.006 0.143,-0.008 0.113,-0.037C0.082,-0.065 0.066,-0.111 0.066,-0.175L0.066,-0.731Z"
          className="text-color"
        />
      </g>
      <g transform="matrix(55.8254,0,0,55.8254,17.5292,0)">
        <path
          d="M0.183,-0.215C0.186,-0.175 0.2,-0.146 0.225,-0.128C0.25,-0.11 0.284,-0.101 0.325,-0.101C0.348,-0.101 0.371,-0.103 0.395,-0.108C0.418,-0.112 0.441,-0.118 0.462,-0.126C0.469,-0.113 0.476,-0.097 0.481,-0.078C0.486,-0.059 0.489,-0.039 0.49,-0.018C0.438,0.002 0.379,0.012 0.314,0.012C0.266,0.012 0.225,0.005 0.191,-0.008C0.156,-0.021 0.128,-0.04 0.107,-0.064C0.085,-0.087 0.069,-0.115 0.059,-0.148C0.048,-0.181 0.043,-0.216 0.043,-0.255C0.043,-0.293 0.048,-0.329 0.059,-0.362C0.07,-0.395 0.086,-0.424 0.107,-0.449C0.128,-0.474 0.154,-0.493 0.185,-0.508C0.216,-0.522 0.252,-0.529 0.293,-0.529C0.329,-0.529 0.361,-0.523 0.389,-0.51C0.417,-0.497 0.441,-0.48 0.461,-0.458C0.48,-0.436 0.495,-0.41 0.505,-0.38C0.515,-0.349 0.52,-0.317 0.52,-0.282C0.52,-0.269 0.52,-0.257 0.519,-0.245C0.518,-0.232 0.516,-0.222 0.515,-0.215L0.183,-0.215ZM0.386,-0.314C0.385,-0.346 0.376,-0.372 0.36,-0.392C0.343,-0.411 0.32,-0.421 0.291,-0.421C0.258,-0.421 0.232,-0.412 0.215,-0.393C0.198,-0.374 0.187,-0.348 0.184,-0.314L0.386,-0.314Z"
          className="text-color"
        />
      </g>
      <g transform="matrix(55.8254,0,0,55.8254,48.3448,0)">
        <path
          d="M0.328,-0.237C0.32,-0.238 0.311,-0.24 0.302,-0.241C0.293,-0.241 0.284,-0.242 0.277,-0.242C0.244,-0.242 0.219,-0.236 0.2,-0.225C0.181,-0.213 0.171,-0.194 0.171,-0.168C0.171,-0.151 0.175,-0.137 0.182,-0.128C0.189,-0.119 0.198,-0.112 0.209,-0.108C0.219,-0.103 0.23,-0.1 0.242,-0.1C0.253,-0.098 0.263,-0.098 0.272,-0.098C0.282,-0.098 0.292,-0.099 0.302,-0.101C0.312,-0.102 0.321,-0.104 0.328,-0.105L0.328,-0.237ZM0.328,-0.34C0.328,-0.356 0.325,-0.369 0.32,-0.379C0.315,-0.389 0.307,-0.397 0.298,-0.403C0.289,-0.408 0.277,-0.412 0.264,-0.414C0.25,-0.416 0.235,-0.417 0.218,-0.417C0.182,-0.417 0.144,-0.41 0.103,-0.396C0.094,-0.413 0.087,-0.43 0.082,-0.445C0.077,-0.46 0.075,-0.479 0.075,-0.5C0.104,-0.51 0.134,-0.517 0.163,-0.522C0.192,-0.527 0.22,-0.529 0.245,-0.529C0.314,-0.529 0.369,-0.512 0.408,-0.479C0.447,-0.446 0.466,-0.392 0.466,-0.319L0.466,-0.019C0.443,-0.012 0.414,-0.005 0.381,0.002C0.348,0.009 0.31,0.012 0.268,0.012C0.234,0.012 0.203,0.009 0.175,0.003C0.146,-0.003 0.122,-0.013 0.102,-0.027C0.081,-0.041 0.066,-0.059 0.055,-0.081C0.044,-0.103 0.038,-0.13 0.038,-0.162C0.038,-0.194 0.045,-0.221 0.059,-0.243C0.072,-0.264 0.09,-0.282 0.112,-0.295C0.133,-0.308 0.157,-0.317 0.184,-0.323C0.211,-0.328 0.237,-0.331 0.264,-0.331C0.283,-0.331 0.305,-0.33 0.328,-0.328L0.328,-0.34Z"
          className="text-color"
        />
      </g>
      <g transform="matrix(55.8254,0,0,55.8254,76.9273,0)">
        <path
          d="M0.38,-0.731C0.392,-0.733 0.404,-0.734 0.416,-0.735C0.427,-0.736 0.439,-0.736 0.45,-0.736C0.461,-0.736 0.473,-0.736 0.485,-0.735C0.497,-0.734 0.509,-0.733 0.521,-0.731L0.521,-0.017C0.49,-0.008 0.458,-0 0.426,0.005C0.393,0.01 0.354,0.012 0.308,0.012C0.273,0.012 0.239,0.007 0.207,-0.003C0.174,-0.012 0.146,-0.028 0.121,-0.049C0.096,-0.07 0.077,-0.097 0.063,-0.13C0.048,-0.163 0.041,-0.204 0.041,-0.252C0.041,-0.29 0.047,-0.326 0.06,-0.36C0.072,-0.393 0.09,-0.422 0.113,-0.447C0.135,-0.472 0.163,-0.491 0.196,-0.506C0.229,-0.52 0.265,-0.527 0.305,-0.527C0.317,-0.527 0.33,-0.527 0.343,-0.526C0.356,-0.525 0.368,-0.522 0.38,-0.519L0.38,-0.731ZM0.38,-0.41C0.367,-0.413 0.356,-0.416 0.348,-0.417C0.339,-0.418 0.329,-0.418 0.316,-0.418C0.294,-0.418 0.275,-0.413 0.259,-0.405C0.242,-0.396 0.229,-0.384 0.218,-0.369C0.207,-0.354 0.2,-0.336 0.195,-0.316C0.19,-0.295 0.187,-0.274 0.187,-0.252C0.187,-0.225 0.19,-0.202 0.197,-0.183C0.204,-0.164 0.213,-0.148 0.225,-0.137C0.236,-0.126 0.25,-0.117 0.266,-0.113C0.282,-0.108 0.299,-0.105 0.318,-0.105C0.329,-0.105 0.34,-0.106 0.349,-0.107C0.358,-0.108 0.369,-0.11 0.38,-0.113L0.38,-0.41Z"
          className="text-color"
        />
      </g>
      <g transform="matrix(55.8254,0,0,55.8254,109.194,0)">
        <path
          d="M0.067,-0.518C0.076,-0.52 0.086,-0.521 0.095,-0.522C0.103,-0.523 0.114,-0.523 0.125,-0.523C0.144,-0.523 0.163,-0.521 0.182,-0.517C0.184,-0.514 0.186,-0.509 0.188,-0.503C0.189,-0.496 0.191,-0.489 0.193,-0.482C0.194,-0.475 0.196,-0.468 0.197,-0.461C0.198,-0.454 0.198,-0.448 0.199,-0.443C0.205,-0.454 0.212,-0.464 0.221,-0.475C0.23,-0.485 0.24,-0.494 0.253,-0.502C0.265,-0.51 0.279,-0.517 0.294,-0.522C0.309,-0.527 0.326,-0.529 0.345,-0.529C0.374,-0.529 0.401,-0.524 0.426,-0.513C0.451,-0.502 0.472,-0.486 0.491,-0.465C0.51,-0.443 0.524,-0.416 0.535,-0.383C0.545,-0.35 0.55,-0.312 0.55,-0.267C0.55,-0.224 0.544,-0.185 0.531,-0.151C0.518,-0.116 0.5,-0.087 0.476,-0.063C0.452,-0.039 0.423,-0.021 0.388,-0.008C0.353,0.006 0.314,0.012 0.27,0.012C0.259,0.012 0.248,0.012 0.237,0.011C0.226,0.01 0.216,0.008 0.209,0.007L0.209,0.237C0.196,0.239 0.184,0.24 0.173,0.241C0.161,0.242 0.149,0.242 0.138,0.242C0.127,0.242 0.115,0.242 0.104,0.241C0.092,0.24 0.08,0.239 0.067,0.237L0.067,-0.518ZM0.209,-0.11C0.227,-0.105 0.248,-0.102 0.271,-0.102C0.312,-0.102 0.344,-0.115 0.366,-0.142C0.388,-0.169 0.399,-0.209 0.399,-0.263C0.399,-0.284 0.397,-0.303 0.394,-0.321C0.391,-0.338 0.385,-0.353 0.377,-0.366C0.369,-0.379 0.359,-0.389 0.347,-0.396C0.334,-0.403 0.319,-0.407 0.301,-0.407C0.284,-0.407 0.27,-0.404 0.259,-0.398C0.247,-0.391 0.237,-0.383 0.23,-0.372C0.223,-0.361 0.217,-0.348 0.214,-0.333C0.211,-0.318 0.209,-0.303 0.209,-0.286L0.209,-0.11Z"
          className="text-color"
        />
      </g>
      <g transform="matrix(55.8254,0,0,55.8254,141.629,0)">
        <path
          d="M0.328,-0.237C0.32,-0.238 0.311,-0.24 0.302,-0.241C0.293,-0.241 0.284,-0.242 0.277,-0.242C0.244,-0.242 0.219,-0.236 0.2,-0.225C0.181,-0.213 0.171,-0.194 0.171,-0.168C0.171,-0.151 0.175,-0.137 0.182,-0.128C0.189,-0.119 0.198,-0.112 0.209,-0.108C0.219,-0.103 0.23,-0.1 0.242,-0.1C0.253,-0.098 0.263,-0.098 0.272,-0.098C0.282,-0.098 0.292,-0.099 0.302,-0.101C0.312,-0.102 0.321,-0.104 0.328,-0.105L0.328,-0.237ZM0.328,-0.34C0.328,-0.356 0.325,-0.369 0.32,-0.379C0.315,-0.389 0.307,-0.397 0.298,-0.403C0.289,-0.408 0.277,-0.412 0.264,-0.414C0.25,-0.416 0.235,-0.417 0.218,-0.417C0.182,-0.417 0.144,-0.41 0.103,-0.396C0.094,-0.413 0.087,-0.43 0.082,-0.445C0.077,-0.46 0.075,-0.479 0.075,-0.5C0.104,-0.51 0.134,-0.517 0.163,-0.522C0.192,-0.527 0.22,-0.529 0.245,-0.529C0.314,-0.529 0.369,-0.512 0.408,-0.479C0.447,-0.446 0.466,-0.392 0.466,-0.319L0.466,-0.019C0.443,-0.012 0.414,-0.005 0.381,0.002C0.348,0.009 0.31,0.012 0.268,0.012C0.234,0.012 0.203,0.009 0.175,0.003C0.146,-0.003 0.122,-0.013 0.102,-0.027C0.081,-0.041 0.066,-0.059 0.055,-0.081C0.044,-0.103 0.038,-0.13 0.038,-0.162C0.038,-0.194 0.045,-0.221 0.059,-0.243C0.072,-0.264 0.09,-0.282 0.112,-0.295C0.133,-0.308 0.157,-0.317 0.184,-0.323C0.211,-0.328 0.237,-0.331 0.264,-0.331C0.283,-0.331 0.305,-0.33 0.328,-0.328L0.328,-0.34Z"
          className="text-color"
        />
      </g>
      <g transform="matrix(55.8254,0,0,55.8254,170.212,0)">
        <path
          d="M0.066,-0.731C0.09,-0.735 0.114,-0.737 0.137,-0.737C0.16,-0.737 0.183,-0.735 0.207,-0.731L0.207,-0.206C0.207,-0.185 0.208,-0.169 0.211,-0.156C0.213,-0.143 0.217,-0.134 0.222,-0.127C0.227,-0.12 0.233,-0.115 0.241,-0.112C0.249,-0.109 0.259,-0.108 0.271,-0.108C0.276,-0.108 0.282,-0.108 0.289,-0.109C0.295,-0.11 0.301,-0.111 0.307,-0.112C0.314,-0.085 0.318,-0.058 0.318,-0.032L0.318,-0.018C0.318,-0.013 0.317,-0.008 0.316,-0.003C0.305,0 0.293,0.003 0.278,0.004C0.263,0.005 0.249,0.006 0.236,0.006C0.185,0.006 0.143,-0.008 0.113,-0.037C0.082,-0.065 0.066,-0.111 0.066,-0.175L0.066,-0.731Z"
          className="text-color"
        />
      </g>
    </g>
  </Box>
);

const Logo = ({
  textColor = "gray.300",
  iconLeftColor = "rgb(0,118,192)",
  iconRightColor = "rgb(6,174,239)",
  width = { base: "80%", lg: "100%" },
  height = "100%"
}) => {
  return (
    <Box overflow="hidden">
      <chakra.svg
        sx={{
          ".text-color": {
            fill: textColor
          },
          ".icon-left": {
            fill: iconLeftColor
          },
          ".icon-right": {
            fill: iconRightColor
          }
        }}
        width={width}
        height={height}
        viewBox={`0 0 592 138`}
        version="1.1"
      >
        <g transform="matrix(0.971673,0,0,0.911512,-121.337,-198.265)">
          <rect
            x="124.875"
            y="217.512"
            width="608.246"
            height="151.328"
            style={{ fill: "none" }}
          />
          <g transform="matrix(1.69447,0,0,1.80631,-644.755,-30.0694)">
            <LogoText />
            <Icon />
          </g>
        </g>
      </chakra.svg>
    </Box>
  );
};

export { Logo };
