import { QuoteScreen } from "./screens/quote";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Routes>
          <Route path="/quote/:quoteId?" element={<QuoteScreen />} />
          <Route path="/" element={<QuoteScreen />} />
        </Routes>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
