import { useState, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Box } from "@chakra-ui/react";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

export const CompanyDocument = ({
  url,
  onError,
  loadingMessage = null,
  onLoadSuccess = _ => {}
}) => {
  const [numPages, setNumPages] = useState(0);
  const containerRef = useRef(null);
  const [width, setWidth] = useState(1);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    setWidth(containerRef.current.getBoundingClientRect().width);
  }, [containerRef]);

  return (
    <Box ref={containerRef}>
      <Box
        as={Document}
        file={url}
        onLoadSuccess={props => {
          setNumPages(props.numPages);
          onLoadSuccess(props);
        }}
        onLoadError={onError}
        loading={loadingMessage}
      >
        {Array(numPages)
          .fill(true)
          .map((_, i) => (
            <Page
              key={`page-${i + 1}`}
              pageNumber={i + 1}
              width={width}
              loading=""
            />
          ))}
      </Box>
    </Box>
  );
};
