import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firebaseConfig = process.env.REACT_APP_TEST
  ? process.env.REACT_APP_TEST_FIREBASE_CONFIG
  : process.env.REACT_APP_FIREBASE_CONFIG;

/* @ts-ignore */
const app = initializeApp(JSON.parse(firebaseConfig));
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west2");

if (window.location.port === "3002" || window.location.port === "3002") {
  connectFunctionsEmulator(functions, "192.168.1.196", 5001);
  connectAuthEmulator(auth, "http://192.168.1.196:9099");
  connectFirestoreEmulator(db, "192.168.1.196", 8080);
}

const getClaims = () => {
  const user = auth.currentUser;

  return user
    ? user.getIdTokenResult().then(x => x.claims)
    : Promise.resolve({});
};

export { db, functions, getClaims, analytics, auth };
