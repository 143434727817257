import { useEffect, useReducer } from "react";
import { Box, Stack, Heading, Image, Text } from "@chakra-ui/react";
import { produce } from "immer";
import { usePageTitle } from "../hooks";
import { quoteOpenedNotificaiton, retreiveQuote } from "../googleFunctions";
import { Basic } from "../components/quote_result/home_heating/basic";
import { getAuth } from "firebase/auth";
import { Loading } from "../components/loading";
import { useParams, useSearchParams } from "react-router-dom";
import notFoundImage from "../images/not_found.svg";
import * as Sentry from "@sentry/browser";

const reducer = produce((state, action) => {
  switch (action.type) {
    case "quote_accepted": {
      state.quote.accepted_date = { _seconds: new Date().getTime() / 1000 };
      state.quote.accepted_index = action.payload.pkgIndex;
      state.quote.accepted_terms = action.payload.acceptedTerms;
      return state;
    }
    case "quote_loaded": {
      state.quote = action.payload.quote;
      state.schema = action.payload.schema;
      state.logo = action.payload.logo;
      state.terms = action.payload.terms;
      state.status = "loaded";
      state.invalidQuoteId = false;
      return state;
    }
    case "not_found": {
      state.status = "invalid";
      return state;
    }
    default: {
      return state;
    }
  }
});

const makeSchemaObj = fields =>
  fields.reduce((agg, field) => {
    if (field.fields) {
      return {
        ...agg,
        [field.field]: makeSchemaObj(field.fields)
      };
    }

    if (field.options) {
      return {
        ...agg,
        [field.field]: {
          ...field,
          ...(agg[field.field] || {}),
          ...makeSchemaObj(field.options)
        }
      };
    }

    return {
      ...agg,
      [field.field || field.value]: {
        ...agg[field.field || field.value],
        ...field
      }
    };
  }, {});

export const QuoteScreen = () => {
  const { quoteId } = useParams();

  usePageTitle((getAuth().currentUser ? "Company" : "Customer") + " Quote");

  const [state, dispatch] = useReducer(reducer, {
    status: "loading",
    open: false,
    quote: {}
  });

  useEffect(() => {
    const id = quoteId?.replace(/[.]+$/, "");

    retreiveQuote({ id })
      .then(data => {
        if (!data.data) {
          dispatch({
            type: "not_found"
          });

          return;
        }
        /* @ts-ignore */
        const { quote, schema, logo, terms } = data.data;

        if (!quote) {
          dispatch({
            type: "not_found"
          });

          return;
        }

        dispatch({
          type: "quote_loaded",
          payload: {
            quote,
            schema: makeSchemaObj(schema.fields),
            logo,
            terms
          }
        });
      })
      .catch(e => {
        Sentry.captureException(e);
      });
  }, [quoteId]);

  return (
    <Loading loading={state.status === "loading"}>
      <Quote
        quoteId={quoteId}
        state={state}
        onAccepted={(pkgIndex, acceptedTerms) => {
          dispatch({
            type: "quote_accepted",
            payload: {
              pkgIndex,
              acceptedTerms
            }
          });
        }}
      />
    </Loading>
  );
};

const ManagedContainer = ({ children, ...styles }) => (
  <Box width="100%" paddingTop={8} paddingBottom={8} {...styles}>
    <Box
      maxWidth="1024px"
      width="100%"
      margin="0 auto"
      paddingLeft={{ base: 4, md: 16 }}
      paddingRight={{ base: 4, md: 16 }}
    >
      {children}
    </Box>
  </Box>
);

const InvalidQuoteId = () => {
  return (
    <ManagedContainer>
      <Stack textAlign="center" spacing={8} fontSize="md">
        <Image src={notFoundImage} width="50%" margin="0 auto" />

        <Heading>This quote could not be found</Heading>

        <Text>
          It looks like the quote ID in this web address does not belong to a
          quote that we can find.
        </Text>
        <Text>
          Please check that the web address is correct, otherwise please contact
          your quote provider.
        </Text>
      </Stack>
    </ManagedContainer>
  );
};

const Quote = ({ quoteId, state, onAccepted }) => {
  usePageTitle((getAuth().currentUser ? "Company" : "Customer") + " Quote");
  const [searchParams] = useSearchParams();

  const preview = searchParams.get("preview") || false;

  useEffect(() => {
    quoteOpenedNotificaiton({ id: quoteId, preview });
  }, [quoteId, preview]);

  if (state.status === "invalid") {
    return <InvalidQuoteId />;
  }

  return (
    <Stack>
      <Box>
        <Basic
          quote={state.quote}
          schema={state.schema}
          logo={state.logo}
          terms={state.terms}
          onAccepted={onAccepted}
        />
      </Box>
    </Stack>
  );
};

export default QuoteScreen;
