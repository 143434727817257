import { PropsWithChildren } from "react";
import React from "react";
import * as Sentry from "@sentry/browser";

type PropsErrorType = { onError?: () => void } & PropsWithChildren;
type PropsStateType = { hasError: boolean };

export class ErrorBoundary extends React.Component<
  PropsErrorType,
  PropsStateType
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    if (this.props.onError) {
      this.props.onError();
    }
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}
