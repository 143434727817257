import * as Sentry from "@sentry/react";

const dsn = process.env.REACT_APP_QUOTE_SENTRY_DSN;

if (dsn) {
  Sentry.init({
    dsn,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new Sentry.Replay({
        maskAllText: true
      })
    ]
  });
}
