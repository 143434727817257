import { useState } from "react";
import {
  Box,
  Heading,
  Text,
  Stack,
  Flex,
  List,
  ListItem,
  Image,
  Link,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  Modal,
  ModalContent,
  ModalFooter,
  ModalBody
} from "@chakra-ui/react";
import { faUserCircle, faCircle } from "@fortawesome/pro-solid-svg-icons";
import { db } from "../../../firebase";
import { format } from "date-fns";
import { Logo } from "../../../components/logo";
import getPathOr from "crocks/helpers/getPathOr";
import party from "../../../images/party.svg";
import { TermsAndConditionsDialog } from "../../termsAndConditions";
import { ErrorBoundary } from "../../errorBoundary";
import { faCheck, faArrowRight } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateDoc, doc } from "firebase/firestore";

const getDestinationType = jobType => {
  const parts = jobType.split("-");
  return parts[parts.length - 1];
};

const buildImagePath = (quote, pkg) => {
  const boilerName = [pkg.boiler, pkg.color].filter(Boolean).join("_");
  const jobType = getDestinationType(quote.job_type);

  return (
    ["/images/boilers", pkg.manufacturer, quote.fuel, jobType, boilerName]
      .filter(Boolean)
      .join("/") + ".png"
  );
};

const CustomerNotes = ({ notes }) => {
  const new_line_delimiter = "<lp_new_line>";

  const paragraphs = notes
    // Replace line breaks with a delimiter we can match on later
    // We do this because all non-printable chars will be removed in the
    // next line
    .replace(/\n/g, new_line_delimiter)
    // Split double new_line_delimiter into paragraphs
    // Account for times where the only chars between one new line
    // and the next, are repeated spaces
    .split(new RegExp(`(${new_line_delimiter})[s]*${new_line_delimiter}`))
    // Remove any empty slots in the list
    .filter(Boolean);

  return (
    <Stack spacing={2}>
      {paragraphs.map((section, i) => (
        <Stack spacing={0} key={`note_paragraph_${i}`}>
          {section
            .split(new_line_delimiter)
            .filter(Boolean)
            .map((line, i) => (
              <Box key={`note_line_${i}`}>{line}</Box>
            ))}
        </Stack>
      ))}
    </Stack>
  );
};

const BoilerImage = ({ quote, pkg }) => {
  return (
    <Box width={{ base: "100px", md: "250px" }}>
      <Image
        width="100%"
        src={buildImagePath(quote, pkg)}
        fallbackSrc={`/images/boiler_fallback/${pkg.manufacturer}.png`}
      />
    </Box>
  );
};

const UserCard = ({
  name,
  address,
  postcode,
  phone,
  email,
  type,
  color,
  ...props
}) => {
  const primary = `${color}.500`;
  const secondary = `${color}.200`;

  return (
    <Box
      borderRadius="5px"
      padding={2}
      background="#fff"
      boxShadow="0px 0px 10px #999"
      {...props}
    >
      <Stack
        border="2px solid"
        borderColor={secondary}
        borderRadius="5px"
        padding={4}
        height="100%"
        spacing={2}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box
            as={FontAwesomeIcon}
            icon={faUserCircle}
            fontSize="2xl"
            color={primary}
          />

          <Stack spacing={0}>
            <Text fontWeight="bold" fontSize="1.3rem">
              <Box
                as="span"
                display="block"
                fontSize="0.7rem"
                fontWeight="normal"
              >
                {type} Details
              </Box>
              {name}
            </Text>
          </Stack>
        </Stack>
        <Box>
          <Text>
            {address} {postcode}
          </Text>
        </Box>
        <Stack direction="row" spacing={4} fontSize="0.8rem">
          {phone && (
            <Stack
              as={Link}
              href={`tel:${phone}`}
              direction="row"
              alignItems="center"
              color="#000000"
              spacing={2}
            >
              <Box as={FontAwesomeIcon} icon={faCircle} color={primary} />
              <Box>{phone}</Box>
            </Stack>
          )}
          {email && (
            <Stack
              as={Link}
              direction="row"
              alignItems="center"
              href={`mailto:${email}`}
              color="#000000"
              spacing={2}
            >
              <Box as={FontAwesomeIcon} icon={faCircle} color={primary} />
              <Box>{email}</Box>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

const storeChosenPackage = (id, packageIndex, accepted_terms = false) =>
  updateDoc(doc(db, "quotes", id), {
    accepted_index: packageIndex,
    accepted_date: new Date(),
    accepted_terms
  });

const Btn = ({ children, accepted, disabled, startIcon, onClick }) => (
  <Button
    leftIcon={startIcon}
    background="orange.400"
    color="white"
    fontWeight="700"
    textTransform="uppercase"
    fontSize="sm"
    width="100%"
    height="100%"
    _hover={{
      background: disabled ? "" : "orange.300"
    }}
    _disabled={{
      background: accepted ? "green.200" : "orange.200",
      color: accepted ? "gray.500" : "white",
      cursor: "not-allowed"
    }}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </Button>
);

const Price = ({ value, incVat, ...styleProps }) => (
  <Stack direction="row" alignItems="center" spacing={2} {...styleProps}>
    <Text fontSize="xl">
      <strong>£{money.format(value)}</strong>{" "}
    </Text>
    <Text>{incVat ? "Inc. VAT" : ""}</Text>
  </Stack>
);

const ManagedContainer = ({ children, ...styles }) => (
  <Box width="100%" paddingTop={8} paddingBottom={8} {...styles}>
    <Box
      maxWidth="1024px"
      width="100%"
      margin="0 auto"
      paddingLeft={{ base: 4, md: 16 }}
      paddingRight={{ base: 4, md: 16 }}
    >
      {children}
    </Box>
  </Box>
);

const money = new Intl.NumberFormat("en-GB", {});

const BenefitItem = ({ children }) => (
  <ListItem>
    <Stack alignItems="center" direction="row" spacing={2}>
      <Box as={FontAwesomeIcon} icon={faCheck} />
      <Box>{children}</Box>
    </Stack>
  </ListItem>
);

type AcceptedPackage = {
  boiler?: string;
  manufacturer?: string;
  price?: number;
};

type ThanksProps = {
  quote: {};
  acceptedPkg: AcceptedPackage;
  company: {
    name: string;
    phone: string;
  };
  optLabel: (key: string) => string;
};

const Thanks = ({ acceptedPkg = {}, company, optLabel }: ThanksProps) => {
  return (
    <Stack direction="row" spacing={{ base: 0, md: 8 }} color="gray.500">
      <Flex
        flex="2"
        display={{ base: "none", md: "block" }}
        justifyContent="flex-end"
      >
        <Box>
          <Image src={party} width="100%" />
        </Box>
      </Flex>
      <Stack flex="3">
        <Stack>
          <Heading margin="0">Thank you</Heading>
          <Text margin="0" fontWeight="bold">
            ...for accepting this quote
          </Text>
        </Stack>

        <Stack spacing={4}>
          <Text>
            We're glad you've chosen {company.name} for this job and we look
            forward to providing you with an excellent service.
          </Text>
          <Heading as="h3" fontSize="md">
            What happens next?
          </Heading>
          <Text>
            One of our members of staff will be in touch to discuss which dates
            suit you best to carry out the work.
          </Text>
          <Heading as="h3" fontSize="md">
            Your chosen package
          </Heading>
          <Text>
            You've chosen a{" "}
            <strong>{optLabel(`packages.boiler.${acceptedPkg.boiler}`)}</strong>{" "}
            by{" "}
            <strong>
              {optLabel(`packages.manufacturer.${acceptedPkg.manufacturer}`)}
            </strong>{" "}
            at a cost of <strong>£{money.format(acceptedPkg.price)}</strong>
          </Text>
          <Text>
            If you'd like to get in touch with us in the meantime, please call
            us on <Link href={`tel:${company.phone}`}>{company.phone}</Link>
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

const ThanksDialog = ({
  open,
  quote,
  company,
  acceptedPkg,
  optLabel,
  onClose
}) => {
  return (
    <Modal isOpen={open} size={{ base: "full", md: "lg" }} onClose={onClose}>
      <ModalContent>
        <ModalBody>
          <Thanks
            quote={quote}
            acceptedPkg={acceptedPkg}
            company={company}
            optLabel={optLabel}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
            onClick={onClose}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const LeadPalBar = ({ logo }) => {
  return (
    <Box top="0" padding={0} width="100%">
      {logo ? (
        <Image src={logo.url} maxHeight="90px" />
      ) : (
        <Box width="190px">
          <Logo textColor="blue.500" />
        </Box>
      )}
    </Box>
  );
};

const findOptionLabel = schema => fieldId => {
  if (!schema || !fieldId) {
    return null;
  }

  return getPathOr(null, [...fieldId.split("."), "label"], schema);
};

const findOptionValue = schema => fieldId => {
  if (!schema || !fieldId) {
    return null;
  }

  return getPathOr(null, [...fieldId.split("."), "value"], schema);
};

const getBenefitLabels = (benefit, quote, pkg, schema) => {
  const optLabel = findOptionLabel(schema);

  const value = pkg[benefit.field] || quote[benefit.field];
  const schemaEntry = schema.packages[benefit.field] || schema[benefit.field];
  const ignore = schemaEntry[value] && schemaEntry[value].ignore_benefit;

  return []
    .concat(ignore ? null : value)
    .filter(Boolean)
    .map(
      value =>
        optLabel(`packages.${benefit.field}.${value}`) ||
        optLabel(`${benefit.field}.${value}`)
    )
    .join(" + ");
};

type BenefitValue = {
  benefit: boolean;
  label: string;
  showBenefitLabel: boolean;
};

type BenefitType = [string, BenefitValue];

type QuoteFieldsType = [
  string,
  {
    private: boolean;
    options?: Array<{
      label: string;
      value: string;
    }>;
  }
];

export const Basic = ({ quote, schema, logo, terms, onAccepted }) => {
  const [matches] = useMediaQuery("(min-width:600px)");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [chosenPackage, setChosenPackage] = useState(null);
  const [chosenIndex, setChosenIndex] = useState(null);
  const [termsBoxOpen, setTermsBoxOpen] = useState(false);
  const theme = useTheme();

  const {
    accepted_index,
    accepted_date,
    company,
    name,
    created_by,
    packages,
    inc_vat,
    intro_note
  } = quote;

  const acceptedPkg = packages[accepted_index];
  const isAccepted = accepted_index !== null && accepted_index >= 0;

  const benefits = [
    ...Object.entries(schema),
    ...Object.entries(schema.packages)
  ]
    .filter(([_, v]: BenefitType) => v.benefit)
    .reduce(
      (agg, [k, v]: BenefitType) => [
        ...agg,
        { field: k, label: v.label, showBenefitLabel: v.showBenefitLabel }
      ],
      []
    );

  const optLabel = findOptionLabel(schema);
  const optValue = findOptionValue(schema);

  const saveChosenPackage = accepted_terms =>
    storeChosenPackage(chosenPackage, chosenIndex, accepted_terms).then(() => {
      setDialogOpen(true);
      setTermsBoxOpen(false);
    });

  return (
    <Box color="#718096">
      <ThanksDialog
        open={dialogOpen}
        quote={quote}
        company={company}
        acceptedPkg={acceptedPkg}
        optLabel={optLabel}
        onClose={() => {
          setDialogOpen(false);
        }}
      />

      <ErrorBoundary
        onError={() => {
          if (!chosenPackage) {
            return;
          }

          saveChosenPackage(false);
        }}
      >
        <TermsAndConditionsDialog
          quote={quote}
          isOpen={termsBoxOpen}
          company={company}
          termsEnabled={!!terms}
          onAccept={({ accepted_terms }) => {
            saveChosenPackage(accepted_terms);
            onAccepted(chosenIndex, accepted_terms);
          }}
          onClose={() => {
            setTermsBoxOpen(false);
          }}
        />
      </ErrorBoundary>

      <ManagedContainer background="#ffffff">
        <Stack spacing={12}>
          <Box>
            <Stack spacing={4}>
              <LeadPalBar logo={logo} />
              {quote.company.logo ? null : (
                <Heading as="h1" margin="0">
                  {company.name} Quote
                </Heading>
              )}
            </Stack>
          </Box>

          <Stack spacing={4}>
            <Heading as="h2" margin="0" fontSize={["2xl", "3xl"]}>
              {name}, Your Quote From {company.name}
            </Heading>
            <Stack direction="row" padding="0">
              <Box>
                Quote reference:{" "}
                <strong>{quote.id.slice(0, 7).toUpperCase()}</strong>
              </Box>
              {quote.last_updated ? (
                <Box
                  borderLeft="1px solid"
                  borderLeftColor="gray.400"
                  paddingLeft={2}
                >
                  <strong>Updated:</strong>{" "}
                  {format(
                    new Date(quote.last_updated._seconds * 1000),
                    "dd-MM-yyyy HH:mm"
                  )}
                </Box>
              ) : (
                <Box
                  borderLeft="1px solid"
                  borderLeftColor="gray.400"
                  paddingLeft={2}
                >
                  <strong>Created:</strong>{" "}
                  {format(
                    new Date(quote.timestamp._seconds * 1000),
                    "dd-MM-yyyy HH:mm"
                  )}
                </Box>
              )}
            </Stack>
          </Stack>

          {isAccepted ? (
            <Box padding="4" border="1px solid #eee" borderRadius="5px">
              <Thanks
                quote={quote}
                acceptedPkg={acceptedPkg}
                company={company}
                optLabel={optLabel}
              />
            </Box>
          ) : null}
        </Stack>
      </ManagedContainer>
      {intro_note ? (
        <ManagedContainer background="blue.50">
          <CustomerNotes notes={intro_note} />
        </ManagedContainer>
      ) : null}
      <Box>
        {packages.map((pkg, i) => {
          const accepted = accepted_index === i;
          return (
            <ManagedContainer
              key={pkg.key}
              background={`linear-gradient(${theme.colors.gray[50]}, ${theme.colors.white})`}
              paddingTop={12}
              paddingBottom={12}
              borderBottom="2px dashed #eee"
              _last={{
                borderBottom: "none"
              }}
            >
              <Box
                borderBottom="3px dashed #eee"
                _last={{
                  borderBottom: "none"
                }}
              >
                <Stack direction="row" spacing={4}>
                  <Stack flex="1" spacing="4">
                    <Heading
                      as="h3"
                      color="gray.400"
                      fontSize="xl"
                      fontWeight="bold"
                      margin="0"
                    >
                      Package {i + 1}
                    </Heading>

                    <Box>
                      <Text fontSize="3xl" fontWeight="bold" margin="0">
                        {optLabel(`packages.manufacturer.${pkg.manufacturer}`)}
                      </Text>

                      <Text>{optLabel(`packages.boiler.${pkg.boiler}`)}</Text>
                    </Box>
                    <List padding="0" spacing={2}>
                      {benefits.map(benefit => {
                        const benefitValueLabels = getBenefitLabels(
                          benefit,
                          quote,
                          pkg,
                          schema
                        );

                        if (benefitValueLabels === "") {
                          return null;
                        }

                        return (
                          <BenefitItem key={benefit.field}>
                            {benefit.showBenefitLabel
                              ? `${benefit.label}: `
                              : null}
                            {benefitValueLabels}
                          </BenefitItem>
                        );
                      })}
                    </List>

                    <Price value={pkg.price} incVat={inc_vat} />
                    <Box width={["100%", "40%"]} height="12">
                      <Box height="100%">
                        <Btn
                          accepted={accepted}
                          disabled={isAccepted || quote.locked}
                          startIcon={
                            accepted ? <FontAwesomeIcon icon={faCheck} /> : null
                          }
                          onClick={() => {
                            if (isAccepted) {
                              return;
                            }

                            setChosenPackage(quote.id);
                            setChosenIndex(i);
                            setTermsBoxOpen(true);
                          }}
                        >
                          {accepted ? "Chosen package" : "Accept This Quote"}
                        </Btn>
                      </Box>
                    </Box>
                    {accepted_date && accepted_index === i ? (
                      <Box>
                        <Text fontSize="0.8em">
                          Accepted on{" "}
                          {format(
                            new Date(accepted_date._seconds * 1000),
                            "dd/MM/yyyy"
                          )}
                        </Text>
                      </Box>
                    ) : null}

                    {pkg.customer_notes ? (
                      <Box
                        border="2px dotted"
                        borderColor="gray.200"
                        padding={4}
                        borderRadius="5px"
                      >
                        <CustomerNotes notes={pkg.customer_notes} />
                      </Box>
                    ) : null}
                  </Stack>

                  <Box position="relative">
                    <Flex
                      position="absolute"
                      top="0"
                      right="-15%"
                      width="100%"
                      height="100%"
                      justifyContent="flex-end"
                      alignItems="center"
                      visibility={{ base: "hidden", md: "visible" }}
                    >
                      <Stack
                        width="125px"
                        height="125px"
                        border="1px solid"
                        borderRadius="50%"
                        justifyContent="center"
                        alignItems="center"
                        backgroundColor="gray.200"
                        borderColor="gray.500"
                        spacing="0"
                      >
                        <Text margin="0" fontSize="lg">
                          <strong>{pkg.guarantee_years} Year</strong>
                        </Text>
                        <Text margin="0">Guarantee</Text>
                      </Stack>
                    </Flex>

                    <BoilerImage quote={quote} pkg={pkg} />
                  </Box>
                </Stack>
              </Box>
            </ManagedContainer>
          );
        })}
      </Box>

      <ManagedContainer background="#ffffff">
        <Box>
          <Heading as="h2" margin="0" fontSize={["2xl", "3xl"]}>
            Specification
          </Heading>

          <Box
            as="ul"
            style={{ columnCount: matches ? 2 : 1, columnWidth: "50%" }}
            fontSize="0.91rem"
            paddingLeft="1rem"
          >
            {Object.entries(schema)
              .filter(
                ([k, v]: QuoteFieldsType) => !v.private && v.options && quote[k]
              )
              .map(([k]) => {
                const label = optLabel(k);
                const value = optLabel(`${k}.${quote[k]}`);
                const optionValue = optValue(`${k}.${quote[k]}`);

                if (!label || value === null || optionValue === "n_a") {
                  return null;
                }

                return (
                  <Box as="li" key={k} paddingTop={2}>
                    <strong>{label}:</strong> {value}
                  </Box>
                );
              })}
          </Box>
        </Box>
      </ManagedContainer>
      <ManagedContainer background={theme.colors.gray[100]}>
        <Grid
          templateColumns="repeat(auto-fill, minmax(350px, 1fr))"
          gap={4}
          autoFlow="row"
        >
          <UserCard
            name={company.name}
            address={company.address}
            postcode={company.postcode}
            phone={company.phone}
            email={company.email}
            type="Company"
            color="yellow"
          />

          <UserCard
            name={created_by.name}
            address={company.address}
            postcode={company.postcode}
            phone={created_by.phone}
            email={created_by.email}
            type="Surveyor"
            color="teal"
          />
        </Grid>
      </ManagedContainer>
    </Box>
  );
};
