import { useState, useEffect } from "react";
import { CompanyDocument } from "./companyDocument";
import { db } from "../firebase";
import {
  Box,
  Stack,
  Button as ChakraButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useBreakpointValue
} from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";

const getDocument = (companyId, documentId) =>
  getDoc(doc(db, `companies/${companyId}/public_docs`, documentId)).then(
    snap => (snap.exists() ? { id: snap.id, ...snap.data() } : null)
  );

const useGetCompanyDocument = (id, companyId, isOpen) => {
  const [doc, setDoc] = useState(undefined);
  useEffect(() => {
    if (isOpen) {
      getDocument(companyId, id)
        .then(setDoc)
        .catch(() => {
          setDoc(null);
        });
    }
  }, [companyId, isOpen, id]);

  return doc;
};

export const TermsAndConditionsDialog = ({
  company,
  onAccept,
  onClose,
  isOpen,
  termsEnabled,
  quote
}) => {
  const size = useBreakpointValue({ base: "full", md: "2xl" });
  const doc = useGetCompanyDocument("terms", company.id, isOpen);
  const [loaded, setLoaded] = useState(false);
  const [accepting, setAccepting] = useState(false);

  if (!isOpen) {
    return null;
  }

  const modalNotRequired = doc === null || (isOpen && !termsEnabled);

  if (modalNotRequired) {
    if (!quote.accepted_date) {
      onAccept({ accepted_terms: false });
    }
    return null;
  }

  if (doc === undefined) {
    return (
      <Modal
        isOpen={isOpen}
        size={size}
        scrollBehavior="inside"
        onClose={() => {}}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>Processing</ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size={size}
      isCentered
    >
      <ModalOverlay />
      <ModalContent margin="0">
        <ModalHeader>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid"
            borderColor="gray.200"
            paddingBottom={4}
          >
            <Box>Terms &amp; Conditions</Box>
            <ChakraButton
              as="a"
              variant="outline"
              fontSize="sm"
              href={doc?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              View as PDF
            </ChakraButton>
          </Stack>
        </ModalHeader>
        <ModalBody>
          <CompanyDocument
            url={doc?.url}
            onLoadSuccess={() => {
              setLoaded(true);
            }}
            onError={() => {
              onAccept({ accepted_terms: false });
            }}
            loadingMessage="Loading Terms &amp; Conditions - Please wait..."
          />
        </ModalBody>
        <ModalFooter>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingTop={4}
            paddingLeft={4}
            width="100%"
            borderTop="1px solid"
            borderColor="gray.200"
          >
            <Stack spacing={2}>
              <Box>
                <strong>
                  I accept the {company.name} Terms and Conditions
                </strong>
              </Box>
            </Stack>
            <Stack direction="row">
              <ChakraButton variant="outline" onClick={onClose}>
                Cancel
              </ChakraButton>
              <ChakraButton
                variant="solid"
                background="green.400"
                color="white"
                _hover={{
                  background: "green.500"
                }}
                onClick={() => {
                  setAccepting(true);
                  onAccept({ accepted_terms: true });
                }}
                disabled={!loaded || accepting}
              >
                {accepting ? "Please wait..." : "Agree"}
              </ChakraButton>
            </Stack>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
